import React from 'react';
import './Subscribe.css';

const Subscribe =()=>{
    return(<div>

<h1>Part 7: Subscribe goes here</h1>

    </div>);
}

export default Subscribe;