import React from 'react';
import './Aboutus.css';

const Aboutus =()=>{
    return(
        <div>
            <h1>Part 3: About us goes here</h1>
        </div>
    );
}

export default Aboutus;