import React from 'react';
import './Donate.css';

const Donate =()=> {
    return (
        <div>
            <h1>Part 5: Donate page</h1>
        </div>
    );
}

export default Donate;